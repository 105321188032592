


















































import { Component, Mixins } from 'vue-property-decorator';
import Mixin from './mixin';
import { Editor } from '@toast-ui/vue-editor';
import { PROJECT_ERROR } from '@/enum/message/project';

@Component
export default class RegisterProjectStep3 extends Mixins(Mixin) {
  $refs!: {
    defaultTos: Editor;
    customTos: Editor;
  };
  constructor() {
    super();
  }

  onEditorChange(name) {
    if (name === 'defaultTos') {
      this.$refs.defaultTos.invoke('setHtml', this.project.defaultTos);
    }
    this.project[name] = this.$refs[name].invoke('getHtml') as string;
  }

  async validate() {
    try {
      if (this.project.stepIndex < 3) this.project.stepIndex = 3;
      this.project.defaultTos = this.$refs.defaultTos.invoke(
        'getHtml'
      ) as string;
      const result = await this.insertProjectData();
      if (!result) {
        this.$toast.error(PROJECT_ERROR.SAVE_FAILURE);
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }
}
