import { Vue, Component, Prop } from 'vue-property-decorator';
import { IProject, PROJECT } from '@/interface/project';
import { PROJECT_WORD, PROJECT_ERROR } from '@/enum/message/project';
import { Editor } from '@toast-ui/vue-editor';
import '@toast-ui/editor/dist/toastui-editor.css';

@Component({
  components: {
    Editor,
  },
})
export default class mixin extends Vue {
  PROJECT: any;
  Editor: Editor;
  PROJECT_ERROR: any = PROJECT_ERROR;
  @Prop() project: IProject;
  @Prop({ default: false }) pending: boolean;
  email: string;
  emailAddr: string;
  editorOption = {
    hideModeSwitch: true,
    placeholder: '참여자에게 소개될 내용',
    autofocus: true,
    toolbarItems: [
      'heading',
      'bold',
      'italic',
      'strike',
      'divider',
      'hr',
      'quote',
      'divider',
      'ul',
      'ol',
      'task',
      'indent',
      'outdent',
      'divider',
      'table',
      // 'image',
      'link',
      'divider',
      'code',
      'codeblock',
    ],
  };

  constructor() {
    super();
    this.PROJECT = PROJECT;
    this.email = '';
    this.emailAddr = '';
  }

  async insertProjectData() {
    try {
      const {title, exposureTitle, interviewType, description, startDate, endDate, managerName, managerTel, managerEmail, defaultTos, customTos, _id, complete, stepIndex} = this.project;
      const sendData: IProject = {
        title,
        exposureTitle,
        interviewType,
        description,
        startDate,
        endDate,
        managerName,
        managerTel,
        managerEmail,
        defaultTos,
        customTos,
        complete,
        stepIndex
      }
      if (_id) sendData._id = _id;

      const { data } = await this.axios({
        url: `/project`,
        method: _id ? 'PATCH' : 'POST',
        data: sendData
      });

      const { result, project }: { result: boolean; project: IProject } = data;
      if (result) {
        const { complete } = project;
        this.$toast.open({
          message: !complete ? '임시 저장' : '수정 완료',
          type: !complete ? 'info' : 'success',
        });
        const { _id } = project;
        this.project._id = _id;
      }
      return result;
    } catch (e) {
      return false;
    }
  }
}
