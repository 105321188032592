import { IOptionValue } from '../utils/interface';
import { ISurvey } from './survey';
import { IParticipant } from './participant';
import { PROJECT } from './project';

export namespace GROUP {
  export const enum GROUP_STATUS {
    EXPECT = 'expect',
    PROGRESS = 'in-progress',
    COMPLETED = 'completed',
    END = 'end',
  }

  export const GROUP_OPTIONS: Array<IOptionValue> = [
    { value: GROUP_STATUS.EXPECT, text: '예정' },
    { value: GROUP_STATUS.PROGRESS, text: '진행중' },
    { value: GROUP_STATUS.COMPLETED, text: '완료' },
    { value: GROUP_STATUS.END, text: '종료' },
  ];

  export const SAMPLE_SIZE: Array<{
    interviewType: PROJECT.INTERVIEW_TYPE;
    min: number;
    max: number;
  }> = [
    { interviewType: PROJECT.INTERVIEW_TYPE.ONE_TO_ONE, min: 2, max: 2 },
    { interviewType: PROJECT.INTERVIEW_TYPE.GROUP, min: 3, max: 5 },
    { interviewType: PROJECT.INTERVIEW_TYPE.GANG, min: 5, max: 9 },
  ];

  export const enum ADDITIONAL_FEATURES {
    BOARD = 'Discussion Board',
    ONLY_CHAT = 'only-chat',
    NOTICE = 'Announcement',
  }

  export const ADDITIONAL_FEATURES_OPTIONS: Array<IOptionValue> = [
    { value: ADDITIONAL_FEATURES.BOARD, text: '게시판' },
    /*{ value: ADDITIONAL_FEATURES.ONLY_CHAT, text: '채팅만 사용' },
    { value: ADDITIONAL_FEATURES.NOTICE, text: '대기실' },*/
  ];

  export function getInitGroup(): IGroup {
    return {
      projectObjectId: null,
      title: '',
      sampleSize: 0,
      price: 0,
      status: '',
      startDate: null,
      lengthOfInterview: 0,
      additionalFeatures: [],
      reword: 0,
      registerUserId: null,
      participants: []
    };
  }
}

export interface IGroup {
  _id?: string; //고유값 ObjectId
  projectObjectId: string | null; // 프로젝트 ObjectId
  registerUserId: string | null; // 등록자 ObjectId
  title: string | null; // 그룹 제목
  sampleSize: number; // 샘플사이즈(비용자동계산목적)
  price: number; // 예상비용
  reword: number; // 참여자들 지급금액
  additionalFeatures: Array<GROUP.ADDITIONAL_FEATURES>; //조사에 추가할 기능(부가기능)
  status?: GROUP.GROUP_STATUS | ''; // 그룹 상태
  startDate: Date | string | null; //시작날짜(datetime YYYY-MM-DD)
  endDate?: Date | string; // 시작날짜(datetime)
  lengthOfInterview: number; // Minute 분으로 설정
  joinPassword?: string; // 참여코드
  twilioRoom?: Object; // Twilio 방 정보
  participants: IParticipant[]
}
