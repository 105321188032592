














import { Component, Vue } from 'vue-property-decorator';
import sideBar from '@/components/common/sideBar.vue';
import NavBar from '@/components/common/navBar.vue';

@Component({
  components: {
    sideBar,
    NavBar,
  },
  computed: {
    NavBar() {
      // const routerExcept: string[] = ['user-login','user-register','user-find-id','user-find-pw','errorPage','user-emailAuth','project-test', 'meeting']
      // const routerName:string = this.$route.name as string;
      // return routerExcept.includes(routerName);
      return this.$route.meta.sideBar;
    },
  },
})
export default class App extends Vue {}
