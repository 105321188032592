



















































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IBoards } from '@/interface/board';
import InfiniteLoading from 'vue-infinite-loading';
import dayjs from 'dayjs';

@Component({
  components: {
    InfiniteLoading,
  },
})
export default class BoardListModal extends Vue {
  @Prop() groupId: string;
  private boardMessage: string = '';
  private page: number = 1;
  boardList: IBoards[] = [];

  constructor() {
    super();
  }

  created() {}

  init() {
    this.page = 1;
    this.boardList = [];
    this.boardMessage = '';
  }

  async infiniteHandler($state): Promise<void> {
    setTimeout(async () => {
      const isAddList = await this.getBoardList(this.page);

      if (isAddList) {
        $state.loaded();
        this.page++;
      } else {
        const noResult = this.page === 1;
        if (noResult) {
          this.boardMessage = '등록된 게시물이 없습니다.';
          this.page = 0;
        }
        $state.complete();
      }
    }, 300);
  }

  async getBoardList(page: number): Promise<boolean> {
    const sendData = {
      page,
      groupId: this.groupId,
    };
    const { data } = await Vue.axios.get('/board', {
      params: sendData,
    });
    data.forEach((value) => {
      this.boardList.push(value);
    });
    return !!data.length;
  }

  @Emit('board-update-open')
  getBoardDetail(boardId: string) {
    return {
      groupId: this.groupId,
      boardId,
    };
  }

  @Emit('board-insert-open')
  boardInsertModal() {
    return this.groupId;
  }

  @Emit('board-list-close')
  listModalClose() {}

  dayJsTest(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD');
  }
}
