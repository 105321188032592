






































import {Component , Vue, Prop} from 'vue-property-decorator'
/*
export default {
  name: "chatNavBar"
}
 */
@Component({ name: 'chatNavBar'})
export default class NavBar extends Vue{
    @Prop(String)  title: string | undefined;

    mounted(){
    }

    async logout(){
        await this.$store.dispatch('logout');
    }
}
