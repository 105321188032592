




































































































































import { Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { BOARD, IBoard_Type, IBoards } from '@/interface/board';
import { setBoardInit, setBoardType } from '@/utils/setting/board';
import BoardViewTop from '@/components/board/sub/BoardViewTop.vue';
import { Editor } from '@toast-ui/vue-editor';

@Component({ components: { BoardViewTop, Editor } })
export default class BoardInsertModal extends Vue {
  @Prop() groupId: string;
  @Prop() titleInfo: BOARD.ITitleInfo;
  @Ref() editor: Editor;
  files: File[] = [];
  private boardType: IBoard_Type[] = setBoardType();
  private board: IBoards = setBoardInit();
  private isLoad: boolean = false;
  editorText: string = ' ';
  editorOptions = {
    hideModeSwitch: true,
    height: '150px',
  };

  @Emit()
  closeModal(isInsert: boolean = false) {
    this.boardReset();
    return isInsert;
  }

  async show() {
    this.boardReset();
    await this.setDefaultInfo();
  }

  async setDefaultInfo() {
    const { data } = await this.axios.get(`/group/${this.groupId}`);
    const { groupInfo } = data;
    const { _id: groupId, projectObjectId: projectId } = groupInfo;
    this.board._id_group = groupId;
    this.board._id_project = projectId;
    this.board.manager = this.$store.getters.getUserName;
    this.board.groupTitle = this.titleInfo.groupTitle;
    this.board.projectTitle = this.titleInfo.projectTitle;
  }

  boardReset() {
    this.board = setBoardInit();
    this.isLoad = false;
    this.files = [];
  }

  async uploadFile() {
    const type = 'board';
    const groupId = this.board._id_group;
    const formData: FormData = new FormData();
    this.files.forEach((file, fileIdx) => {
      formData.append('fileList', file);
    });
    const { data } = await this.axios.post(`/upload/${groupId}/${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    this.board.filesDir = data;
  }

  //#TODO 백엔드 로직 동일 적용
  get allConfirm(): boolean {
    if (!this.board.title) {
      return false;
    }
    if (this.board.reservation) {
      if (!this.board.startDate || !this.board.endDate) {
        return false;
      }
    }

    return true;
  }

  async createBoard() {
    await this.setContent();
    if (!this.board.content) {
      this.$toast.info('내용을 작성하지 않았습니다.');
      return;
    }
    if (!this.allConfirm) {
      this.$toast.info('미 작성된 부분이 있습니다.');
      return;
    }

    this.isLoad = true;
    try {
      await this.uploadFile();
      const { data } = await this.axios.post(`/board`, this.board);
      if (data.result) {
        this.closeModal(true);
        this.isLoad = false;
      }
    } catch (e) {
      console.log('-----error----');
      console.log(e.message);
      console.log('-----error----');
    } finally {
      this.isLoad = false;
    }
  }

  async setContent() {
    this.board.content = await this.getContent();
  }

  getContent(): Promise<string> {
    return new Promise((resolve) => {
      resolve(this.editor.invoke('getHtml'));
    });
  }

  get projectTitle(): string {
    return this.titleInfo.projectTitle;
  }

  get projectManagerName(): string {
    return this.board.manager;
  }

  get groupName(): string {
    return this.titleInfo.groupTitle;
  }

  resetReservationDate() {
    this.board.startDate = undefined;
    this.board.endDate = undefined;
  }

  get isReservation(): boolean {
    if (!this.board.reservation) this.resetReservationDate();
    return this.board.reservation;
  }
}
