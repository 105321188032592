export enum BOARD_BACKEND_URL {
  INSERT = '/project/boardCreate',
  DELETE = '/project/boardDelete',
  UPDATE = '/project/boardUpdate',
  GET_LIST = '',
  DEFAULT = '',
}

export interface IBoard {
  _id?: string | null;
  groupObjectId: string;
  projectId: string;
  topHolding: boolean;
  type: BOARD_TYPE;
  title: string;
  content: string;
  managerName: string;
  reservation: boolean;
  startTime?: string;
  endTime?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  projectTitle: string;
  groupTitle: string;
  createDate?: Date;
  updateDate?: Date;
}

export interface IBoards {
  _id?: string;
  _id_project: string;
  _id_group: string;
  category: BOARD_TYPE;
  manager: string;
  title: string;
  content: string;
  reservation: boolean;
  isFix: boolean;
  createDate?: Date;
  updateDate?: Date;
  startDate?: Date | string;
  endDate?: Date | string;
  filesDir?: {
    fileName: string;
    location: string;
    idx?: number;
    isUse?: boolean;
  }[];
  reply?: [];
  projectTitle?: string;
  groupTitle?: string;
  studyComplete?: { _id: string; userEmail: string }[];
}

export interface IReply {
  replyWriter: string[];
  content: string[];
  createDate: Date;
  updateDate: Date;
  isUse: boolean;
  comments: [];
}

export enum BOARD_TYPE {
  NOTICE = '공지사항',
  STUDY = '과제',
}

export enum BOARD_SELECT_TYPE {
  INSERT = '등록',
  UPDATE = '수정',
  VIEW = '조회',
  DELETE = '삭제',
  DEFAULT = '',
}

export const boardInit: IBoards = {
  _id: '',
  _id_project: '',
  _id_group: '',
  category: BOARD_TYPE.STUDY,
  manager: '',
  title: '',
  content: '',
  reservation: false,
  isFix: false,
  updateDate: new Date(),
  startDate: new Date(),
  endDate: new Date(),
  filesDir: [],
};

export const boardDefault: IBoard = {
  _id: '',
  groupObjectId: '',
  projectId: '',
  topHolding: false,
  type: BOARD_TYPE.STUDY,
  title: '',
  content: '',
  managerName: '',
  reservation: false,
  startTime: '',
  endTime: '',
  startDateTime: undefined,
  endDateTime: undefined,
  groupTitle: '',
  projectTitle: '',
};

export interface boardInsertFormData {
  projectTitle: string;
  groupTitle: string;
  managerName: string;
}

export interface IBoard_Type {
  text: BOARD_TYPE;
  value: BOARD_TYPE;
}

export namespace BOARD {
  export interface I_Id_TitleInfo {
    groupId: string;
    titleInfo: ITitleInfo;
  }

  export interface ITitleInfo {
    projectTitle: string;
    groupTitle: string;
  }
}
