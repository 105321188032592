<template>
  <b-tbody>
    <b-tr>
      <b-td colspan="12"> 등록된 프로젝트가 없습니다.</b-td>
    </b-tr>
  </b-tbody>
</template>

<script>
export default {
  name: 'BoardNone',
};
</script>

<style scoped></style>
