import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/css/bootstrap.min.css';
import VueAxios from 'vue-axios';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { ins as axios } from './utils/axios';
import Notifications from 'vue-notification';
import * as VueMoment from 'vue-moment';
import VueDaumPostcode from 'vue-daum-postcode';
import Validation from '@/utils/validation';
import Element from 'element-ui';
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import locale from 'element-ui/lib/locale/lang/en';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import '@/plugins/Dayjs';
import '@/assets/css/globals.css';




Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueToast, { position: 'top-right' });
Vue.use(PerfectScrollbar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMoment);
Vue.use(VueDaumPostcode);
Vue.use(Validation);
Vue.use(Element, { locale });
Vue.use(VueFormWizard);
Vue.config.productionTip = false;


const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default vm;
