







































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import Mixin from './mixin';
import { PROJECT_ERROR } from '@/enum/message/project';

@Component
export default class RegisterProjectStep2 extends Mixins(Mixin) {
  constructor() {
    super();
  }

  get startDateState() {
    return this.project.startDate !== '';
  }

  get endDateState() {
    return this.project.endDate !== '';
  }

  get managerNameState() {
    return this.project.managerName !== '';
  }

  get managerTelState() {
    return this.project.managerTel.trim().length > 7;
  }

  get emailState() {
    return this.email.trim().length > 1;
  }

  get emailAddrState() {
    return this.emailAddr.trim().length > 1;
  }

  @Watch('email')
  @Watch('emailAddr')
  concatEmail() {
    const emailReg = /[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
    const emailAddrReg = /[`~!@#$%^&*()_|+=?;:'",<>\{\}\[\]\\\/]/gi;
    this.email = this.email.replace(emailReg, '');
    this.emailAddr = this.emailAddr.replace(emailAddrReg, '');
    this.project.managerEmail = `${this.email}@${this.emailAddr}`;
  }

  async validate() {
    try {
      const { startDate, endDate, managerName, managerTel, managerEmail } = this.project;
      if (startDate === '' || endDate === '') {
        this.$toast.error(PROJECT_ERROR.PROGRESS);
        return false;
      }

      const dateStart = new Date(startDate);
      const dateEnd = new Date(endDate);
      if (dateStart > dateEnd) {
        this.$toast.error(PROJECT_ERROR.PROGRESS_RANGE);
        return false;
      }

      if (managerName === '') {
        this.$toast.error(PROJECT_ERROR.MANAGER_NAME);
        return false;
      }
      if (managerTel.trim().length < 7) {
        this.$toast.error(PROJECT_ERROR.MANAGER_TEL);
        return false;
      }
      const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
      if (!this.$validation.emailCheck(managerEmail) || !regEmail.test(managerEmail)) {
        this.$toast.error(PROJECT_ERROR.MANAGER_EMAIL);
        return false;
      }

      if (this.project.stepIndex < 2) this.project.stepIndex = 2;
      const result = await this.insertProjectData();
      if (!result) {
        this.$toast.error(PROJECT_ERROR.SAVE_FAILURE);
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  splitEmail(managerEmail: string) {
    const mailArray = managerEmail.split('@');
    if (mailArray.length > 0) {
      this.email = mailArray[0] || '';
      this.emailAddr = mailArray[1] || '';
    } else {
      this.email = '';
      this.emailAddr = '';
    }
  }
}
