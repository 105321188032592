






import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { PROJECT } from '@/interface/project';
import { IOptionValue } from '@/utils/interface';
import PROJECT_STATUS = PROJECT.PROJECT_STATUS;

@Component({
  components: {},
})
export default class ListTab extends Vue {
  @PropSync('status') syncStatus: PROJECT.PROJECT_STATUS;
  @Prop({
    default: {
      all: false,
      expect: false,
      progress: false,
      completed: false,
    },
    required: false,
  })
  allOff: {
    all: boolean;
    expect: boolean;
    progress: boolean;
    completed: false;
  };
  projectTabs: Array<IOptionValue> = PROJECT.PROJECT_OPTIONS;
  listIndex: number = 0;

  listTabChange() {
    this.syncStatus = this.projectTabs[this.listIndex].value as PROJECT.PROJECT_STATUS;
    this.changeHandle();
  }

  @Emit()
  changeHandle() {}

  //#TODO : 각 OFF 항목 커스텀
  mounted() {
    if (this.allOff.all) this.projectTabs = this.projectTabs.filter((v) => v.value !== PROJECT_STATUS.ALL);
  }
}
