import { render, staticRenderFns } from "./registerByStep2.vue?vue&type=template&id=5086a5c9&scoped=true&"
import script from "./registerByStep2.vue?vue&type=script&lang=ts&"
export * from "./registerByStep2.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5086a5c9",
  null
  
)

export default component.exports