import {IOptionValue} from "@/utils/interface";


export namespace SURVEY {
    export const enum QUESTION_TYPE {
        RADIO = 'radio',
        RADIO_SET = 'radioSet',
        CHECK = 'check',
        TEXT = 'text',
    }

    export const QUESTION_TYPE_OPTIONS: Array<IOptionValue> = [
        {value: QUESTION_TYPE.RADIO , text: '단일'},
        {value: QUESTION_TYPE.RADIO_SET, text: '단일(표)'},
        {value: QUESTION_TYPE.CHECK, text: '복수'},
        {value: QUESTION_TYPE.TEXT, text: '오픈'},
    ];

    export function getInitSurvey(): ISurvey{
        return {
            questionNumber: '',
            questionType: '',
            questionContents: '',
            examples: [],
            ord: 0,
            use: true
        }
    }

    export function getInitQuestion(): IQuestion{
        return {
            questionNumber: '',
            questionType: '',
            questionContents: '',
            examples: [],
            ord: 0,
            use: true,
            change: 0,
        }
    }
}

export interface ISurvey {
    _id?: string;
    questionNumber: string; // 문항명
    questionType: SURVEY.QUESTION_TYPE | ''; // 문항 타입
    questionContents: string; // 질문
    examples: Array<IOptionValue>;
    ord: number; //순서
    use: boolean; //사용여부
}

export interface IQuestion {
    _id?: string;
    _id_group?: string;
    questionNumber: string;
    questionType: SURVEY.QUESTION_TYPE | ''; // 문항 타입
    questionContents: string; // 질문
    examples: Array<IOptionValue>;
    ord: number; //순서
    use: boolean; //사용여부
    change: number;
}
