import { IStudy } from '@/interface/study';

export function setStudyInit(): IStudy {
  return {
    _id_board: '',
    title: '',
    content: '',
    userEmail: '',
    filesDir: [],
  };
}

export function setGetStudyInit(): IStudy {
  return {
    _id: '',
    _id_board: '',
    title: '',
    content: '',
    userEmail: '',
    filesDir: [],
  };
}
