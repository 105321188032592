export enum PROJECT_ERROR {
  SAVE_FAILURE = '프로젝트 저장을 실패하였습니다.',
  TITLE = '프로젝트 제목을 3글자 이상 입력해 주세요.',
  TITLE_FEEDBACK = '3글자 이상 입력해 주세요.',
  EXPOSURE_TITLE = '프로젝트 노출 제목을 3글자 이상 입력해 주세요.',
  EXPOSURE_TITLE_FEEDBACK = '3글자 이상 입력해 주세요.',
  INTERVIEW_TYPE = 'Interview Type을 선택하여 주세요.',
  INTERVIEW_TYPE_FEEDBACK = '보기중 하나를 선택하여 주세요.',
  INTERVIEW_TYPE_NOT_UPDATE = '* 등록된 그룹이 있어 Interview Type을 수정할 수 없습니다.',
  DESCRIPTION = '프로젝트 소개를 입력해 주세요.',
  DESCRIPTION_FEEDBACK = '프로젝트 소개를 입력해 주세요.',
  PROGRESS = '프로젝트 진행기간을 선택해 주세요.',
  PROGRESS_RANGE = '프로젝트 진행 날짜를 잘못 선택하였습니다. 다시 확인해 주세요.',
  START_DATE_FEEDBACK = '시작 날짜를 선택해 주세요.',
  END_DATE_FEEDBACK = '종료 날짜를 선택해 주세요.',
  MANAGER_NAME = '담당자 이름을 입력해 주세요.',
  MANAGER_NAME_FEEDBACK = '담당자 이름을 입력해 주세요.',
  MANAGER_TEL = '담당자 전화번호를 입력해 주세요.',
  MANAGER_TEL_FEEDBACK = '담당자 전화번호를 입력해 주세요.',
  MANAGER_EMAIL = '담당자 이메일 형식을 다시 입력해 주세요.',
  MANAGER_EMAIL_HEAD = '이메일 앞부분을 입력해 주세요.',
  MANAGER_EMAIL_TAIL = '이메일 뒷부분을 입력해 주세요.',
  NOT_COMPLETE = '프로젝트 등록을 완료 한 후 이용할 수 있습니다.'
}

export enum PROJECT_WORD {
  COMPLETE = '프로젝트 정보가 모두 저장되었습니다.',
}
