





























import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import TopShortcutRight from '@/components/common/shortcut/TopShortcutRight.vue';
import TopShortcutMiddleTime from '@/components/common/shortcut/TopShortcutMiddle-Time.vue';
import TopShortcutLeft from '@/components/common/shortcut/TopShortcutLeft.vue';
import { IProject, PROJECT } from '@/interface/project';
import TopShortcutMiddleNone from '@/components/common/shortcut/TopShortcutMiddle-None.vue';
import { ShortCutMiddle } from '@/enum/common';
import RegisterByProject from '@/components/project/registerByProject.vue';
import RegisterByGroup from '@/components/group/registerByGroup.vue';
import ListTab from '@/components/common/tab/ListTab.vue';
import { GROUP } from '@/interface/group';
import PROJECT_STATUS = PROJECT.PROJECT_STATUS;

@Component({
  components: {
    ListTab,
    RegisterByGroup,
    RegisterByProject,
    TopShortcutMiddleNone,
    TopShortcutLeft,
    TopShortcutMiddleTime,
    TopShortcutRight,
  },
})
export default class MainLayout extends Vue {
  @PropSync('projectList', { default: [] }) syncProjectList: IProject[];

  @Prop() middleType: ShortCutMiddle;
  @Prop({
    default: {
      all: false,
      expect: false,
      progress: false,
      completed: false,
    },
    required: false,
  })
  isAllOff: {
    all: boolean;
    expect: boolean;
    progress: boolean;
    completed: false;
  };
  @Prop({ default: String, required: false })
  additionalFeatures: GROUP.ADDITIONAL_FEATURES;

  status: PROJECT.PROJECT_STATUS = PROJECT_STATUS.EXPECT;
  sort: number = -1;
  page: number = 1;
  limitPage: number = 10;
  totalRow: number = 0;
  search: string = '';

  @Emit('changeHandle')
  async changeHandle() {
    await this.setProjectList();
  }

  async mounted() {
    await this.setProjectList();
  }

  async setProjectList(): Promise<void> {
    try {
      const sendData = {
        status: this.status,
        page: this.page,
        limitPage: this.limitPage,
        search: this.search,
        sort: this.sort,
        additionalFeatures: this.additionalFeatures,
      };
      const { data } = await this.axios.get(`/project`, {
        params: sendData,
      });
      const { projects, total } = data;
      this.totalRow = total;
      this.syncProjectList = projects.map((p: any) => {
        return {
          groupShow: false,
          ...p,
        };
      });
    } catch (e) {
      console.log(e);
    }
  }
}
