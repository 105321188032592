const userPath : any = {
    userJoin :[
        {
            path: 'register',
            name: 'user-register',
            meta: {
                sideBar: false
            },
            component: () => import('@/views/user/Register.vue'),
        },
        {
            path: 'login',
            name: 'user-login',
            meta: {
                sideBar: false
            },
            component: ()=> import('@/views/user/Login.vue'),
        },
        {
            path: 'findPW',
            name: 'user-find-pw',
            meta: {
                sideBar: false
            },
            component: ()=> import('@/views/user/FindPW.vue'),
        },
        {
            path: 'authFail/:email',
            name: 'user-emailAuth',
            meta: {
                sideBar: false
            },
            component: ()=> import('@/views/user/AuthFail.vue'),
        },
        {
            path: 'authOk',
            name: 'authOk',
            meta: {
                sideBar: false
            },
            component: ()=> import('@/views/user/AuthOk.vue'),
        },
        {
            path: 'authOk/:message',
            name: 'authOK_Already',
            meta: {
                sideBar: false
            },
            component: ()=> import('@/views/user/AuthOk.vue'),
        },
        {
            path: 'authFindPW/:token',
            name: 'FindPW_AuthOk',
            meta: {
                sideBar: false
            },
            component: ()=> import('@/views/user/AuthFindPW.vue'),
        },
    ]

}
export default userPath
