













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IParticipant, PARTICIPANT } from '@/interface/participant';

@Component
export default class ParticipantByCard extends Vue {
  @Prop({type: String, default: null}) groupId: string;
  PARTICIPANT: any = PARTICIPANT;
  participants: IParticipant[] = [];

  created() {
    this.load()
  }

  async load() {
    try {
      const {data} = await this.axios({
        url: `group-participant/${this.groupId}`
      });
      const {result, list} = data;
      if (result) this.participants = list;
    } catch (e) {
      console.error(e);
    }
  }
}
