import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
Vue.use(VueCookies);

Vue.$cookies.config('1d');

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isLogin: false,
    accessToken: null,
    _id: null,
    userEmail: null,
    isAuth: null,
    isParticipant: false,
    participantCategory: null,
    invitationCategory: null,
    userName: null,
  },
  mutations: {
    login(
      state,
      {
        token,
        _id,
        userEmail,
        userName,
        participantCategory,
        invitationCategory,
      }
    ) {
      state.isLogin = true;
      state._id = _id;
      state.userName = userName;
      state.accessToken = token;
      state.userEmail = userEmail;
      state.participantCategory = participantCategory;
      state.invitationCategory = invitationCategory;
    },
    logout(state) {
      state.isLogin = false;
      state._id = null;
      state.accessToken = null;
      state.userName = null;
      state.userEmail = null;
      state.participantCategory = null;
      state.invitationCategory = null;
    },
    notAuth(state, { userEmail, isAuth }) {
      state.userEmail = userEmail;
      state.isAuth = isAuth;
    },
    setToken(state, token) {
      state.accessToken = token;
    },
  },
  getters: {
    isLogin: (state) => {
      return state.isLogin;
    },
    isParticipant: (state) => {
      return state.isParticipant;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
    getUserObjectId: (state) => state._id,
    getUserEmail: (state) => state.userEmail,
    getUserName: (state) => state.userName,
    getInvitationCategory: (state) => state.invitationCategory,
    getParticipantCategory: (state) => state.participantCategory,
    getIsAuth: (state) => state.isAuth,
  },
  actions: {

    async getCookies({commit}){
      const cookies = Vue.$cookies.get("vuex");
      if(cookies){
        return {userId:cookies.userId}
      }else{
        location.href = '/user/login';
      }
    },
    async login({ commit }, user) {
      try {
        const { data } = await Vue.axios({
          url: '/user/login',
          method: 'POST',
          data: user,
        });

        const {
          result,
          token,
          _id,
          isAuth,
          userEmail,
          userName,
          userEmailAuthDate,
          error,
          participantCategory,
          invitationCategory,
        } = data;

        if (result && data.token && isAuth) {
          commit('login', {
            token,
            _id,
            userEmail,
            userName,
            participantCategory,
            invitationCategory,
          });
          location.href = '/project';
        }

        if (result && data.token && !isAuth) {
          commit('notAuth', { userEmail, isAuth });
          Vue.$toast.open({
            message: '이메일을 확인해주세요',
            type: 'warning',
            duration: 5000,
          });
        }
      } catch (e) {
        //console.log(e);
      }
    },
    async setToken({ commit }, token) {
      commit('token', token);
    },
    async logout({ commit }) {
      Vue.$cookies.remove("vuex")
      commit('logout');
      //await router.push('/user/login');
      location.href = '/user/login';
    },

    async verify({ commit }) {
      try {
        const { data } = await Vue.axios({
          url: `/user/verify`,
          method: 'GET',
        });
        const {
          result,
          token,
          _id,
          userEmail,
          userName,
          invitationCategory,
          participantCategory,
        } = data;
        if (result && token)
          commit('login', {
            token,
            _id,
            userEmail,
            userName,
            participantCategory,
            invitationCategory,
          });
        if (!result) commit('logout');
        return { result };
      } catch (e) {
        console.log(e);
      }
    },
  },
  modules: {},
});
