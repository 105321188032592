
































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { PROJECT, IProject } from '@/interface/project';
import { GROUP, IGroup } from '../../interface/group';
import ParticipantByCard from '@/components/group/participants/index.vue';
import SurveyList from '@/components/group/survey/index.vue';
import GROUP_STATUS = GROUP.GROUP_STATUS;
import dayjs from 'dayjs';
import ADDITIONAL_FEATURES = GROUP.ADDITIONAL_FEATURES;
import copy from 'copy-to-clipboard';

const moderatorLoginUrlDev: string = 'https://192.168.2.243:8081/auth';
const moderatorLoginUrlProd: string = 'https://fgd-video.pmidata.co.kr/auth';
@Component({
  components: { ParticipantByCard, SurveyList },
})
export default class RegisterByGroup extends Vue {
  @Prop() groupId: string;
  @Prop() projectInfo: IProject;
  pending: boolean = false;
  savePending: boolean = false;
  tabIndex: number = 0;
  groupInfo: IGroup = GROUP.getInitGroup();
  PROJECT: any = PROJECT;
  GROUP: any = GROUP;
  sampleSizeOptions:  number[] = [];

  get getProjectTitle() {
    return this.projectInfo ? this.projectInfo.title : '';
  }

  get statusDisabled(): boolean {
    const { status } = this.groupInfo;
    if (!status) return false;
    if (status === GROUP_STATUS.COMPLETED || status === GROUP_STATUS.END) {
      return true;
    }
    return false;
  }

  get projectEndDate():boolean {
    if (!this.projectInfo) return false;
    if (this.statusDisabled) return false;
    const nowDate = this.$moment().format('YYYY-MM-DD');
    const endDate = this.$moment(this.projectInfo.endDate);
    const flag = this.$moment(nowDate).isAfter(endDate);
    return flag;
  }

  sampleSizeLange () {
    const {interviewType} = this.projectInfo;
    this.sampleSizeOptions = [];

    let end = 0;
    let start = 0;
    if (interviewType === PROJECT.INTERVIEW_TYPE.ONE_TO_ONE) {
      start = 2;
      end = 2;
    } else if (interviewType === PROJECT.INTERVIEW_TYPE.GROUP) {
      start = 3;
      end = 8;
    } else if (interviewType === PROJECT.INTERVIEW_TYPE.GANG) {
      start = 9;
      end = 20;
    }
    for(let i=start; i<=end; i++) {
      this.sampleSizeOptions.push(i);
    }
  }

  async load() {
    this.groupInfo = GROUP.getInitGroup();
    this.sampleSizeLange();

    if (!this.groupId) return;
    this.pending = true;
    try {
      const { data } = await this.axios({
        url: `/group/${this.groupId}`,
        method: 'GET',
      });
      const { result, groupInfo }: { result: true; groupInfo: IGroup } = data;
      if (result) {
        const { startDate } = groupInfo;
        groupInfo.startDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss');
        this.groupInfo = groupInfo;
      }
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }

  closeGroupModal(): void {
    this.$bvModal.hide('groupModal');
  }

  async save(): Promise<void> {
    const { _id: projectId } = this.projectInfo;
    const { title, sampleSize, price, startDate, lengthOfInterview, reword, additionalFeatures, status } = this.groupInfo;

    if (title === null || title === '') {
      this.$toast.error(`그룹명을 입력해 주세요.`);
      return;
    }
    if (sampleSize === 0) {
      this.$toast.error(`그룹 표본 수를 1명 이상 입력해 주세요.`);
      return;
    }
    if (startDate === null) {
      this.$toast.error(`인터뷰 시작 날짜 시간을 선택해 주세요.`);
      return;
    }
    if (lengthOfInterview === 0) {
      this.$toast.error(`인터뷰 예상시간을 입력해 주세요.`);
      return;
    }
    if (reword === 0) {
      this.$toast.error(`인터뷰 지급금액을 입력하여 주세요.`);
      return;
    }
    if (projectId === undefined || projectId === "") {
      this.$toast.error(`프로젝트 ID가 존재하지 않습니다.`);
      return
    }

    this.savePending = true;
    try {
      const type = !!this.groupInfo._id;
      const sendData: {_id?: string, projectObjectId: string, title: string, startDate: string | Date, lengthOfInterview: number, sampleSize: number, price: number, reword: number, additionalFeatures: ADDITIONAL_FEATURES[], status?: GROUP_STATUS} = {
        projectObjectId: projectId,
        title,
        sampleSize: Number(sampleSize),
        price: Number(price),
        startDate,
        lengthOfInterview: Number(lengthOfInterview),
        reword: Number(reword),
        additionalFeatures,
      }
      if (type) sendData._id = this.groupInfo._id;
      if (status) sendData.status = status;

      const { data } = await this.axios({
        url: '/group',
        method: type ? 'PATCH' : 'POST',
        data: sendData,
      });
      const { result, groupObjectId } = data;
      if (result) {
        this.$toast.open({
          message: `그룹을 저장하였습니다.`,
          type: 'success',
        });
        this.$emit('closeModal');
        //this.closeGroupModal();
        if(!type) {
          this.groupInfo._id = groupObjectId;
          const groupInfo = await this.getGroupInfo();
          if(groupInfo) {
            groupInfo.startDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss');
            this.groupInfo = groupInfo;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    this.savePending = false;
  }

  async getGroupInfo(): Promise<IGroup | null>  {
    try {
      const { data } = await this.axios({
        url: `/group/${this.groupInfo._id}`,
        method: 'GET',
      });
      const { result, groupInfo }: { result: true; groupInfo: IGroup } = data;
      if (result) {
        return groupInfo;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async interviewStart() {
    const groupInfo = await this.getGroupInfo();
    if (groupInfo === null) return this.$toast.open({
      message: `그룹 정보를 불러오지 못했습니다.`,
      type: 'error',
      duration: 3000
    });

    const nowDate = this.$moment().format('YYYY-MM-DD');
    const startDate = this.$moment(groupInfo.startDate);
    const flag = this.$moment(nowDate).isAfter(startDate);
    if (flag) return this.$toast.open({
      message: `인터뷰 시작날짜가 오늘날짜 이전입니다. 시작날짜를 다시 선택한 후 저장을 눌러주세요.`,
      type: 'error',
      duration: 5000
    });

    const ok = await this.$bvModal.msgBoxConfirm(
        `좌담회를 시작하시겠습니까?`,
        {
          title: '좌담회 시작',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: '시작',
          cancelTitle: '취소',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
    );
    if (!ok) return;

    const userEmail = this.$store.getters.getUserEmail;
    const { _id } = this.groupInfo;
    const url = process.env.NODE_ENV === 'development' ? moderatorLoginUrlDev : moderatorLoginUrlProd;
    window.open(`${url}/${_id}/${userEmail}`);
  }

  joinPwdCopy() {
    const pwd = this.groupInfo.joinPassword || '';
    const copyCheck = copy(pwd);
    if (copyCheck) return this.$toast.open({
      message: `${pwd} 코드가 복사되었습니다.`,
      type: 'success',
    });
  }
}
