














































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IProject, PROJECT } from '@/interface/project';
import { Editor } from '@toast-ui/vue-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import RegisterByStep1 from '@/components/project/registerByStep1.vue';
import RegisterByStep2 from '@/components/project/registerByStep2.vue';
import RegisterByStep3 from '@/components/project/registerByStep3.vue';
import RegisterByStep4 from '@/components/project/registerByStep4.vue';
import { PROJECT_WORD } from '@/enum/message/project';

@Component({
  components: {
    RegisterByStep1,
    RegisterByStep2,
    RegisterByStep3,
    RegisterByStep4,
    Editor,
  },
})
export default class RegisterByProject extends Vue {
  pending: boolean = false;
  btnPending: boolean = false;
  @Prop() projectId: string;
  @Prop() startIndex: number;
  project: IProject = PROJECT.getInitProject();
  $refs!: {
    wizard: HTMLFormElement;
    registerByStep2: RegisterByStep2;
  };

  constructor() {
    super();
  }

  mounted() {
    for (let i = 1; i <= this.startIndex; i++) {
      this.$refs.wizard.changeTab(0, i);
    }
  }

  async load() {
    if (!this.projectId) return;
    this.pending = true;
    try {
      const { data } = await this.axios({
        url: `/project/${this.projectId}`,
        method: 'GET',
      });

      const { result, projectInfo } = data;
      if (result) {
        this.project = projectInfo;
        this.pending = false;
        const { managerEmail } = projectInfo;
        if (managerEmail) {
          this.$nextTick(() => {
            this.$refs.registerByStep2.splitEmail(managerEmail);
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  validate(ref) {
    return this.$refs[ref].validate();
  }

  async onComplete() {
    this.btnPending = true;
    try {
      const { _id } = this.project;
      const { data } = await this.axios({
        url: `/project/${_id}`,
        method: 'PATCH',
      });
      const { result } = data;
      if (result) {
        this.$bvModal.hide('createProjectModal');
        this.$toast.open({
          message: PROJECT_WORD.COMPLETE,
          type: 'success',
        });
        this.$emit('closeModal');
      }
    } catch (e) {
      console.error(e);
    }
    this.btnPending = false;
  }
}
