








































































import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';
import { IProject } from '@/interface/project';
import { GROUP } from '@/interface/group';
import dayjs from 'dayjs';
import { BOARD } from '@/interface/board';

@Component({ components: {} })
export default class BoardListTr extends Vue {
  @PropSync('ProjectArray') projectArray: Array<IProject>;

  mounted() {}

  groupStatusColor(status: GROUP.GROUP_STATUS): string {
    if (status == GROUP.GROUP_STATUS.EXPECT) return 'dark';
    else if (status == GROUP.GROUP_STATUS.END) return 'danger';
    else if (status == GROUP.GROUP_STATUS.PROGRESS) return 'info';
    else if (status == GROUP.GROUP_STATUS.COMPLETED) return 'success';
    else return `secondary`;
  }

  projectStatusColor(status: string): string {
    if (status == '대기') return 'warning';
    else if (status == '예정') return 'dark';
    else if (status == '진행') return 'info';
    else if (status == '완료') return 'success';
    else return `secondary`;
  }

  getGroupStatus(status: GROUP.GROUP_STATUS): string {
    const find = GROUP.GROUP_OPTIONS.find((option) => status == option.value);
    return find == undefined ? '-' : find.text;
  }

  getProgressTime(length: number): string {
    const hour = Math.floor(length / 60);
    const hourStr = hour < 1 ? '' : `${hour}Hour `;
    const minute = length % 60;
    const minuteStr = minute === 0 ? '' : `${minute}Minute`;
    return hourStr + minuteStr;
  }

  @Emit('boardListView')
  boardListView(groupId: string, titleInfo: BOARD.ITitleInfo) {
    return {
      groupId,
      titleInfo,
    };
  }

  getNameInfo(projectTitle: string, groupTitle: string): BOARD.ITitleInfo {
    return {
      projectTitle,
      groupTitle,
    };
  }

  getStartDate(item: Date | string): string {
    return dayjs(item).format('YY-MM-DD HH:MM');
  }
}
