





































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import MainLayout from '@/components/common/layout/MainLayout.vue';
import { IProject, PROJECT } from '@/interface/project';
import ListTab from '@/components/common/tab/ListTab.vue';
import { ShortCutMiddle } from '@/enum/common';
import DefaultTable from '@/components/common/defaultTable.vue';
import BoardListTr from '@/components/board/sub/BoardListTr.vue';
import BoardListModal from '@/components/board/table/BoardList.vue';
import BoardInsertModal from '@/components/board/modal/BoardInsertModal.vue';
import BoardNone from '@/components/board/table/BoardNone.vue';
import { BOARD } from '@/interface/board';
import { COMMON } from '@/interface/common';
import BoardUpdateModal from '@/components/board/modal/BoardUpdateModal.vue';
import { GROUP } from '@/interface/group';
import BoardStudyModal from '@/components/board/study/BoardStudyModal.vue';
import IStatus = COMMON.IStatus;
import ITitleInfo = BOARD.ITitleInfo;
import I_Id_TitleInfo = BOARD.I_Id_TitleInfo;

@Component({
  components: {
    BoardStudyModal,
    BoardUpdateModal,
    BoardNone,
    BoardInsertModal,
    BoardListModal,
    ListTab,
    BoardListTr,
    DefaultTable,
    MainLayout,
  },
})
export default class BoardView extends Vue {
  @Ref('mainLayout') mainLayout;
  @Prop({ default: false, required: false }) isOff: boolean;

  private middleType: ShortCutMiddle = ShortCutMiddle.NONE;
  status: PROJECT.PROJECT_STATUS = PROJECT.PROJECT_STATUS.EXPECT;
  additionalFeatures: GROUP.ADDITIONAL_FEATURES = GROUP.ADDITIONAL_FEATURES.BOARD;

  offInfo: IStatus = {
    all: true,
    expect: false,
    progress: false,
    completed: false,
  };

  projectList: IProject[] = [];
  viewGroupId: string = '';
  groupId: string = '';
  viewBoardId: string = '';
  viewUserEmail: string = '';

  titleInfo: ITitleInfo = {
    groupTitle: '',
    projectTitle: '',
  };

  async boardListOpen({ groupId, titleInfo }: I_Id_TitleInfo) {
    this.viewGroupId = groupId;
    this.titleInfo = titleInfo as ITitleInfo;
    await this.$nextTick();
    this.$bvModal.show('boardList');
  }

  async detailModalDelete() {
    this.$bvModal.hide('boardUpdate');
    await this.$nextTick();
    this.listReset();
  }

  listReset() {
    this.$bvModal.hide('boardList');
    this.$bvModal.show('boardList');
  }

  async insertModalOpen(groupId: string) {
    this.viewGroupId = groupId;
    await this.$nextTick();
    this.$bvModal.show('boardInsert');
  }

  async insertModalClose(isInsert: boolean) {
    this.$bvModal.hide('boardInsert');
    await this.$nextTick();
    if (isInsert) this.listReset();
  }

  async boardListClose() {
    this.$bvModal.hide('boardList');
  }

  async updateModalOpen({ groupId, boardId }: { groupId: string; boardId: string }) {
    this.viewBoardId = boardId;
    this.groupId = groupId;
    await this.$nextTick();
    this.$bvModal.show('boardUpdate');
  }

  async studyModalOpen(participantEmail: string) {
    this.viewUserEmail = participantEmail;
    await this.$nextTick();
    this.$bvModal.show('modal-study');
  }

  async updateModalClose(isUpdate: boolean) {
    this.$bvModal.hide('boardUpdate');
    await this.$nextTick();
    if (isUpdate) this.listReset();
  }

  async studyModalClose() {
    this.$bvModal.hide('modal-study');
  }
}
