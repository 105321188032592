import { BOARD_TYPE, IBoard, IBoards } from '@/interface/board';

export function setBoardType() {
  return [
    { value: BOARD_TYPE.NOTICE, text: BOARD_TYPE.NOTICE },
    { value: BOARD_TYPE.STUDY, text: BOARD_TYPE.STUDY },
  ];
}

export function setBoardDefault(): IBoard {
  return {
    _id: '',
    groupObjectId: '',
    projectId: '',
    topHolding: false,
    type: BOARD_TYPE.STUDY,
    title: '',
    content: '',
    managerName: '',
    reservation: false,
    startTime: '',
    endTime: '',
    startDateTime: undefined,
    endDateTime: undefined,
    groupTitle: '',
    projectTitle: '',
  };
}

export function setBoardInit(): IBoards {
  return {
    _id_project: '',
    _id_group: '',
    category: BOARD_TYPE.NOTICE,
    manager: '',
    title: '',
    content: '',
    reservation: false,
    isFix: false,
    updateDate: new Date(),
    startDate: undefined,
    endDate: undefined,
    filesDir: [],
  };
}
