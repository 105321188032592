


































import '@toast-ui/editor/dist/toastui-editor.css';
import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';
import { IStudy } from '@/interface/study';
import { setGetStudyInit } from '@/utils/setting/study';

@Component({ components: {} })
export default class BoardStudyModal extends Vue {
  @PropSync('userEmail') syncUserEmail: string;
  @PropSync('boardId') syncBoardId: string;

  files: File[] = [];
  subTitles: string = '';
  getStudy: IStudy = setGetStudyInit();

  @Emit()
  studyModalClose() {}

  hide() {
    this.studyReset();
  }

  studyReset() {
    this.getStudy = setGetStudyInit();
  }

  async show() {
    if (!this.syncUserEmail) return;
    const { data } = await this.axios.get(`/study/${this.syncBoardId}/${this.syncUserEmail}`);
    if (data) {
      this.subTitles = data.title;
      this.getStudy = data;
    } else {
      this.subTitles = '';
    }
  }
}
