




























































































import { Component, Vue } from 'vue-property-decorator';
import '@fortawesome/fontawesome-free/css/all.css';
import { sideMenus } from '@/utils/setting/common';

@Component({})
export default class SideBar extends Vue {
  title: string = 'FGD-ONLINE';
  toggleId: string = 'nav-toggle';
  navbarId: string = 'navbar';
  bodyId: string = 'body-pd';
  width: number = 0;
  toggle: HTMLElement | null = null;
  navbar: HTMLElement | null = null;
  bodyPadding: HTMLElement | null = null;
  private menu = sideMenus;
  // private menu: any = [
  //   {
  //     href: '/project',
  //     title: '프로젝트',
  //     icon: 'fa fa-list',
  //   },
  //   {
  //     href: '/participant/list',
  //     title: '참여자 관리',
  //     icon: 'fa fa-users',
  //   },
  //   {
  //     href: '/participant/invitation',
  //     title: '초대하기',
  //     icon: 'fa fa-paper-plane',
  //     collapseFlag: false,
  //     collapseMenu: [
  //       {
  //         href: '/participant/invitation',
  //         subTitle: 'participant',
  //         icon: 'fa fa-user-friends',
  //       },
  //       {
  //         href: '/project/invitation',
  //         subTitle: 'Project',
  //         icon: 'fa fa-list-alt',
  //       },
  //     ],
  //   },
  //
  //   {
  //     href: '/board',
  //     title: '게시판 관리',
  //     icon: 'fa fa-clipboard-list',
  //   },
  //   {
  //     href: '/project/result-report',
  //     title: '결과보기',
  //     icon: 'fa fa-chart-line',
  //   },
  //   /*
  //       {
  //           href: '/email/template',
  //           title: '메일 폼 관리',
  //           icon: 'fa fa-envelope-open-text'
  //       },
  //
  //          */
  // ];

  constructor() {
    super();
  }

  mounted() {
    //사이드 메뉴 우선 열고
    this.showMenu();
    this.width = window.innerWidth;

    //첫화면 로딩시 사이즈 검사해서 닫자
    if (this.width < 450) {
      this.toggleClick();
    }

    //화면 사이즈 변동되면 사이드 바 닫을지 말지 결정
    window.addEventListener('resize', this.sideBarReSize);
  }

  //
  sideBarReSize() {
    this.width = window.innerWidth;
    this.bodyPadding = document.getElementById(this.bodyId);

    if (this.bodyPadding) {
      if (this.bodyPadding?.className.indexOf('body-pd') > -1 && this.width < 450) {
        this.toggleClick();
      }
    }
  }

  collapse(index: number) {
    const row = this.menu[index];
    const { collapseFlag } = row;
    row.collapseFlag = !collapseFlag;
    document.getElementById(`collapse_menu_${index}`)?.classList.toggle('showCollapse');
    document.getElementById(`rotate-icon-${index}`)?.classList.toggle('rotate');
  }

  collapseActive(index: number): boolean {
    const row = this.menu[index];
    const { collapseMenu } = row;
    const check = collapseMenu?.filter((item: any) => item.href == this.$route.fullPath);
    if (check) {
      return check.length > 0;
    } else {
      return false;
    }
  }

  showMenu() {
    this.toggle = document.getElementById(this.toggleId);
    this.navbar = document.getElementById(this.navbarId);
    this.bodyPadding = document.getElementById(this.bodyId);

    if (this.toggle && this.navbar) {
      this.navbar.classList.toggle('expander');
      this.bodyPadding?.classList.toggle('body-pd');
    }
  }

  toggleClick() {
    this.navbar = document.getElementById(this.navbarId);
    this.bodyPadding = document.getElementById(this.bodyId);
    this.navbar?.classList.toggle('expander');
    this.bodyPadding?.classList.toggle('body-pd');
  }

  async logout() {
    await this.$store.dispatch('logout');
  }

  async created() {
    //console.log(this.$route)
  }

  async createdProject() {
    this.$bvModal.show('createProjectModal');
    this.$nextTick(() => {
      // this.$refs.registerByProject.load();
    });
  }
}
