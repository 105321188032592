import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Store from '../store/index';
import userPath from '@/router/user/index';
import BoardView from '@/views/board/index.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/404',
    // 아래처럼 바로 NotFound 경로를 매칭해도 됨
    // component: NotFound
  },
  {
    path: '/404',
    name: 'errorPage',
    meta: {
      sideBar: false,
    },
    component: () => import('@/views/PageNotFound.vue'),
  },
  {
    path: '/',
    redirect: '/user/login',
    meta: {
      userAuth: false,
      sideBar: false,
    },
  },
  {
    path: '/integration',
    meta: {
      userAuth: false,
      sideBar: false,
      integration:true
    },
  },
  {
    path: '/user',
    name: 'user-join',
    component: () => import('../views/user/componets/joinUserCommon.vue'),
    meta: {
      sideBar: false,
    },
    children: userPath.userJoin,
  },
  {
    path: '/project',
    name: 'project',
    meta: {
      userAuth: true,
      sideBar: true,
    },
    component: () => import('../views/project/index.vue'),
  },
  {
    path: '/participant/invitation',
    name: 'participant-invitation',
    meta: {
      userAuth: true,
      sideBar: true,
    },
    component: () => import('../views/participant/Invitation.vue'),
  },
  {
    path: '/participant/list',
    name: 'participant-list',
    meta: {
      userAuth: true,
      sideBar: true,
    },
    component: () => import('../views/participant/List.vue'),
  },
  {
    path: '/email/template',
    name: 'emailTemplate',
    meta: {
      userAuth: true,
      sideBar: true,
    },
    component: () => import('@/views/etc/EmailTemplate.vue'),
  },
  {
    path: '/project/invitation',
    name: 'projectInvitation',
    meta: {
      userAuth: true,
      sideBar: true,
    },
    component: () => import('@/views/project/Invitation.vue'),
  },
  {
    path: '/board',
    name: 'Board',
    meta: {
      userAuth: true,
      sideBar: true,
    },
    component: BoardView,
  },
  {
    path: '/project/result-report',
    name: 'projectResultReport',
    meta: {
      userAuth: true,
      sideBar: true,
    },
    component: () => import('@/views/project/ResultReport.vue'),
  },
  {
    path: '/project/group-report/:groupObjectId',
    name: 'projectGroupReport',
    meta: {
      userAuth: true,
      sideBar: false,
    },
    component: () => import('@/views/project/GroupReport.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { matched, meta } = to;
  const {integration} = meta
  if(integration){
    console.log(integration)
    const {userId}= await Store.dispatch('getCookies');
    await Store.dispatch('login',{userEmail:userId,userPassword:userId});
  }
  if (matched.some(({ meta }) => meta.userAuth)) {
    const { result } = await Store.dispatch('verify');
    if (!result) return next(`/`);
  }
  return next();
});

export default router;
