export interface SideMenu {
  href: string;
  title: string;
  icon: string;
  collapseFlag?: boolean;
  collapseMenu?: {
    href: string;
    subTitle: string;
    icon: string;
  }[];
  md?: number;
}

export const sideMenus: SideMenu[] = [
  {
    href: '/project',
    title: '프로젝트',
    icon: 'fa fa-list',
  },
  {
    href: '/participant/list',
    title: '참여자 관리',
    icon: 'fa fa-users',
  },
  {
    href: '/participant/invitation',
    title: '초대하기',
    icon: 'fa fa-paper-plane',
    collapseFlag: false,
    collapseMenu: [
      {
        href: '/participant/invitation',
        subTitle: 'participant',
        icon: 'fa fa-user-friends',
      },
      {
        href: '/project/invitation',
        subTitle: 'Project',
        icon: 'fa fa-list-alt',
      },
    ],
  },
  {
    href: '/board',
    title: '게시판 관리',
    icon: 'fa fa-clipboard-list',
  },
  {
    href: '/project/result-report',
    title: '결과보기',
    icon: 'fa fa-chart-line',
  },
];

export const sideUrl: SideMenu[] = [
  {
    href: '/project',
    title: '프로젝트',
    icon: 'fa fa-list',
    md: 9,
  },
  {
    href: '/participant/list',
    title: '참여자 관리',
    icon: 'fa fa-users',
    md: 9,
  },

  {
    href: '/participant/invitation',
    title: '참여자 초대',
    icon: 'fa fa-user-friends',
    md: 6,
  },
  {
    href: '/project/invitation',
    title: '프로젝트 초대',
    icon: 'fa fa-list-alt',
    md: 9,
  },
  {
    href: '/board',
    title: '게시판 관리',
    icon: 'fa fa-clipboard-list',
    md: 9,
  },
  {
    href: '/project/result-report',
    title: '결과보기',
    icon: 'fa fa-chart-line',
    md: 6,
  },
];
