import { PluginObject } from 'vue';

class ValidationClass {
    //핸드폰 정규식
    private mobileTelRegex: RegExp  = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
    //이메일 정규식
    private emailRegex: RegExp  = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    //숫자만
    private numberRegex: RegExp  = /[^0-9]/g;
    //숫자 콤마
    private commaNumberRegex: RegExp  = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;

    mobileTelCheck(input: string): boolean{
        return this.mobileTelRegex.test(input);
    }

    emailCheck(input: string): boolean{
        return this.emailRegex.test(input);
    }

    replaceOnlyNumber(str: string): string{
        return str.replace(this.numberRegex,'');
    }

    replaceComma(str: number | string): string{
        return String(str).replace(this.commaNumberRegex,',');
    }

    trim(str: string): string{
        return str.replace(/ /gi, '');
    }

}

declare module 'vue/types/vue' {
    interface Vue {
        $validation: ValidationClass
    }
}

export default {
    install(Vue: any){
        Vue.prototype.$validation = new ValidationClass();
    }
}
