











import { Component, Vue } from 'vue-property-decorator';
import { SideMenu, sideUrl } from '@/utils/setting/common';

@Component({ components: {} })
export default class TopShortcutLeft extends Vue {
  routerName = this.$route.path;

  get linkInfo(): SideMenu {
    const defaultValue = { href: '', icon: '', title: '' };
    if (this.routerName) {
      const findLink = sideUrl.find((linkInfo) => linkInfo.href === this.routerName);
      if (findLink) return findLink;
    }
    return defaultValue;
  }
}
