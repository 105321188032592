import { IOptionValue } from "../utils/interface";
import { IGroup } from "./group";

export namespace PROJECT {
  export const enum PROJECT_STATUS {
    ALL = "all",
    EXPECT = "expect",
    PROGRESS = "in-progress",
    COMPLETED = "completed",
  }

  export const PROJECT_OPTIONS: Array<IOptionValue> = [
    { value: PROJECT_STATUS.ALL, text: "ALL" },
    { value: PROJECT_STATUS.EXPECT, text: "예정" },
    { value: PROJECT_STATUS.PROGRESS, text: "진행" },
    { value: PROJECT_STATUS.COMPLETED, text: "완료" },
  ];

  export const enum INTERVIEW_TYPE {
    ONE_TO_ONE = "1:1 In-depth Interview",
    GROUP = "Focus Group Interview",
    GANG = "Gang",
    //HUT = 'HUT',
  }

  export const INTERVIEW_TYPE_OPTIONS: Array<IOptionValue> = [
    { value: INTERVIEW_TYPE.ONE_TO_ONE, text: INTERVIEW_TYPE.ONE_TO_ONE },
    { value: INTERVIEW_TYPE.GROUP, text: INTERVIEW_TYPE.GROUP },
    { value: INTERVIEW_TYPE.GANG, text: INTERVIEW_TYPE.GANG },
    //{value: INTERVIEW_TYPE.HUT, text: INTERVIEW_TYPE.HUT},
  ];

  export function getInitProject(): IProject {
    return {
      title: "",
      exposureTitle: "",
      description: "",
      interviewType: "",
      defaultTos:
        "제1조 목적\n" +
        '이 약관은 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")"에 의하여 ㈜서베이피플(이하 "회사"라 합니다)이 제공하는 fgd-online 서비스(이하 "서비스"라 합니다)의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.\n' +
        "\n" +
        "제2조 약관의 효력과 변경\n" +
        '1. 본 약관은 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")" 규정에 의하여 제정한 것이며 공시함으로써 효력을 발생합니다. 본 약관의 공시는 서비스 홈페이지에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력을 발생합니다.\n' +
        "2. 회사는 원활한 서비스 제공 및 서비스 개선을 목적으로 본 약관을 변경할 수 있으며, 약관 개정시 사전에 회원에게 공지하여 변경된 사항에 대해서 인지할 수 있도록 조치합니다.\n" +
        "3. 본 약관의 적용기간은 고객이 서비스 이용계약을 신청한 때부터 서비스 계약해지 후 비용 정산이 완료될 때까지로 규정합니다.\n" +
        "\n" +
        "제3조 용어의 정의\n" +
        "1. 본 약관에서 사용하는 용어는 다음과 같습니다.\n" +
        "① 회원 : 서비스를 이용 신청을 통해 최종 승낙을 받아 이용자 ID를 부여 받은 자를 의미합니다. 서비스의 회원은 기업회원과 일반회원이 있습니다.\n" +
        "② 회원 ID : 서비스 가입회원의 식별을 위하여 부여하는 고유한 ID로 인증이 완료된 이메일 주소를 사용합니다.\n" +
        "③ 비밀번호 : 회원의 서비스 이용의 권익보호를 위하여 회원이 선정할 수 있는 숫자와 문자의 조합을 말합니다.\n" +
        "④ 계약해지 : 회사나 신청자가 서비스 개통 후 서비스 이용계약을 해약하는 것을 말합니다. 이는 회원탈퇴를 전제로 합니다.\n" +
        "⑤ 서비스 중단 : 회사가 일정기간 서비스 이용을 제한 또는 유보하는 것을 말합니다.\n" +
        "⑥ 서비스 홈페이지 : 회원이 서비스를 이용하는 온라인 홈페이지(www.fgd-online.co.kr)를 말합니다.\n" +
        "⑦ 사전통보 : 회원의 서비스 이용 중단 및 서비스 이용 제한 등의 사유가 발생시, 회사가 해당 사유에 상응하는 조치를 취하기 전에 회원에게 온라인을 통해 알리는 것을 말합니다.\n",
      customTos: "",
      startDate: "",
      endDate: "",
      managerName: "",
      managerTel: "",
      managerEmail: "",
      stepIndex: 0,
      complete: false
    };
  }
}

export interface IProject {
  _id?: string; //고유값 ObjectId
  title: string; //프로젝트 제목
  exposureTitle: string; //노출제목
  interviewType: PROJECT.INTERVIEW_TYPE | ""; // 프로젝트 인터뷰 타입
  description: string; // 프로젝트 소개 (참여자에게 보여줌)
  startDate: string; //프로젝트 기간(시작날짜) YYYY-MM-DD
  endDate: string; //프로젝트 기간(종료날짜) YYYY-MM-DD
  managerName: string; //담당자 이름
  managerTel: string; //담당자 전화번호
  managerEmail: string; //담당자 이메일
  defaultTos: string; //기본 PMI 제공이용약관
  customTos: string; // 커스텀 이용약관
  insertDate?: Date; //등록날짜
  updateDate?: Date; // 수정날짜
  stepIndex: number;
  complete: boolean;
  groups?: IGroup[] | [];
  status?: string;
  groupShow?: boolean;
}
