












































































































import { Component, Mixins } from 'vue-property-decorator';
import Mixin from './mixin';
import { Editor } from '@toast-ui/vue-editor';

@Component
export default class RegisterProjectStep1 extends Mixins(Mixin) {
  $refs!: {
    description: Editor;
  };

  get complete() {
    return this.project.complete;
  }

  get titleState() {
    return this.project.title.trim().length > 2;
  }

  get exposureTitleState() {
    return this.project.exposureTitle.trim().length > 2;
  }

  get interviewTypeState() {
    return Boolean(this.project.interviewType);
  }

  onEditorChange() {
    this.project.description = this.$refs.description.invoke(
      'getHtml'
    ) as string;
  }

  async validate() {
    try {
      const { title, exposureTitle, interviewType, description } = this.project;
      if (title.trim().length < 3) {
        this.$toast.error(this.PROJECT_ERROR.TITLE);
        return false;
      }

      if (exposureTitle.trim().length < 3) {
        this.$toast.error(this.PROJECT_ERROR.EXPOSURE_TITLE);
        return false;
      }

      if (interviewType === '') {
        this.$toast.error(this.PROJECT_ERROR.INTERVIEW_TYPE);
        return false;
      }

      if (description === '') {
        this.$toast.error(this.PROJECT_ERROR.DESCRIPTION);
        return false;
      }

      if (this.project.stepIndex < 1) this.project.stepIndex = 1;
      const result = await this.insertProjectData();
      if (!result) {
        this.$toast.error(this.PROJECT_ERROR.SAVE_FAILURE);
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }
}
