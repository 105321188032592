
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class BoardViewTop extends Vue {
  @Prop() managerName: string;
}
