































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISurvey, SURVEY } from '@/interface/survey';

@Component
export default class SurveyList extends Vue {
  @Prop({ type: String, default: null }) groupId: string;
  @Prop({type: Boolean, default: false}) statusDisabled: boolean;
  question: ISurvey = SURVEY.getInitSurvey();
  padding: Boolean = false;
  survey: ISurvey[] = [];
  SURVEY_INTERFACE = SURVEY;

  created() {
    this.load();
  }

  async load() {
    try {
      const {data} = await this.axios({
        url: `question/${this.groupId}`
      });
      const {result, questions} = data;
      if (result) {
        this.survey = questions
      }
    } catch (e) {
      console.error(e);
    }
  }

  addSurvey(): void {
    const maxOrd = this.survey.reduce((max, cur) => {
        return cur.ord > max ? cur.ord : max;
      }, 0) || 0;
    this.question = SURVEY.getInitSurvey();
    this.question.questionType = SURVEY.QUESTION_TYPE.RADIO;
    this.question.ord = maxOrd + 1;
    this.question.questionNumber = `Q${this.question.ord}`;
    this.questionTypeChange();
  }

  questionTypeChange(): void {
    const { questionType } = this.question;
    if (!this.question.examples) this.question.examples = [];

    if (questionType === SURVEY.QUESTION_TYPE.TEXT) {
      this.question.examples = [];
    } else if (this.question.examples.length < 2) {
      this.question.examples = [
        { text: '', value: '1' },
        { text: '', value: '2' },
      ];
    }
  }

  examplesAdd() {
    const { examples } = this.question;
    const maxOrd =
      examples.reduce((max, cur) => {
        return Number(cur.value) > max ? Number(cur.value) : max;
      }, 0) || 0;
    this.question.examples.push({ value: String(maxOrd + 1), text: '' });
  }

  deleteExamples(index: number) {
    let value = 0;
    this.question.examples.splice(index, 1);
    const setExamples = this.question.examples.map((e) => {
      const { text } = e;
      return {
        value: String(++value),
        text: text,
      };
    });
    this.question.examples = setExamples;
  }

  async questionRemove() {
    const { questionNumber, _id } = this.question;
    if (_id === undefined) return;

    const ok = await this.$bvModal.msgBoxConfirm(
      `[${questionNumber}] 문항을 삭제하시겠습니까? 삭제 후 복구는 불가능 합니다. `,
      {
        title: `[${questionNumber}]문항 삭제`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '삭제',
        cancelTitle: '취소',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }
    );
    if (!ok) return;

    this.padding = true;
    try {
      const { data } = await this.axios.delete( `question/${_id}`);
      const { result } = data;

      if (result) {
        const index = this.survey.findIndex(f => f._id === _id);
        this.survey.splice(index, 1);
        this.$toast.success(`삭제되었습니다.`);
        this.question = SURVEY.getInitSurvey();
      }
    } catch (e) {
      console.error(e)
    }
    this.padding = false;
  }

  async surveySave() {
    const { examples, questionType, questionContents, questionNumber } = this.question;

    if (this.$validation.trim(questionNumber).length < 2) {
      this.$toast.error(`문항 번호가 입력되지 않았습니다.`);
      return;

    } else if (questionContents.length < 3) {
      this.$toast.error(`질문(3글자 이상)을 입력해주세요.`);
      return;

    } else if (questionType !== SURVEY.QUESTION_TYPE.TEXT) {
      for (let i = 0; i < examples.length; i++) {
        const { value, text } = examples[i];
        if (text.trim() === '') {
          this.$toast.error(`${value}번째 보기를 입력해주세요.`);
          return;
        }
      }
    }

    this.padding = true;
    try {
      const {_id, ord, questionNumber, questionType, questionContents, examples, use} = this.question;
      const sendData: ISurvey = {
        ord, questionNumber, questionType, questionContents, examples, use
      };
      if (_id) sendData._id = _id;

      const { data } = await this.axios({
       url: `question/${this.groupId}`,
       method: _id ? 'PATCH' : 'POST',
       data: sendData
      });

      const { result, questionId } = data;
      if (result) {
        if (questionId) {
          sendData._id = questionId;
          this.survey.push(sendData);
        } else {
          const index = this.survey.findIndex(f => f._id === sendData._id);
          this.survey[index] = sendData;
        }

        this.$toast.success(`문항이 ${_id ? '수정' : '저장'}되었습니다.`);
        this.question = SURVEY.getInitSurvey();
      }
    } catch (e) {
      console.log(e);
    }
    this.padding = false;
  }
}
