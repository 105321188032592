

































































































































































































import { Emit, PropSync, Ref, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { IBoard_Type, IBoards } from '@/interface/board';
import { setBoardInit, setBoardType } from '@/utils/setting/board';
import BoardViewTop from '@/components/board/sub/BoardViewTop.vue';
import { Editor, Viewer } from '@toast-ui/vue-editor';
import dayjs from 'dayjs';
import { IParticipant } from '@/interface/participant';
import BoardStudy from '@/components/board/study/BoardStudyModal.vue';

@Component({ components: { BoardStudy, BoardViewTop, Editor, Viewer } })
export default class BoardUpdateModal extends Vue {
  @PropSync('boardId') syncBoardId: string;
  @PropSync('groupId') syncGroupId: string;
  @Ref() editor: Editor;

  private userEmail: string = '';
  private boardType: IBoard_Type[] = setBoardType();
  private board: IBoards = setBoardInit();
  private participants: IParticipant[] = [];
  files: File[] = [];
  isLoad: boolean = false;
  editorOptions = {
    hideModeSwitch: true,
    height: '150px',
  };

  async show() {
    this.boardReset();
    await this.getParticipantsInfo();
    await this.getBoardDetail();

    this.setTime();
  }

  async getParticipantsInfo() {
    const { data } = await this.axios.get(`/group-participant/${this.syncGroupId}`);
    this.participants = data.list;
  }

  setTime() {
    if (this.board.startDate) {
      const day = dayjs(this.board.startDate);
      const YHD_FORMAT = day.format('YYYY-MM-DD');
      const HN_FORMAT = day.format('HH:MM');
      const TYPE = 'T';
      this.board.startDate = `${YHD_FORMAT}${TYPE}${HN_FORMAT}`;
    }

    if (this.board.endDate) {
      const day = dayjs(this.board.endDate);
      const YHD_FORMAT = day.format('YYYY-MM-DD');
      const HN_FORMAT = day.format('HH:MM');
      const TYPE = 'T';
      this.board.endDate = `${YHD_FORMAT}${TYPE}${HN_FORMAT}`;
    }
  }

  async removeFile(idx: number) {
    if (this.board.filesDir) {
      this.board.filesDir[idx].isUse = false;
    }
  }

  get getUseFile(): {
    fileName: string;
    location: string;
    idx?: number;
    isUse?: boolean;
  }[] {
    if (this.board.filesDir) {
      return this.board.filesDir?.filter((v) => v.isUse);
    } else {
      return [];
    }
  }

  async getBoardDetail() {
    const { data } = await this.axios.get(`/board/${this.syncBoardId}`);
    this.board = data;
    this.participants.map((participant) => {
      const findCompleteUser = this.board.studyComplete?.find((v) => v.userEmail === participant.email);
      if (findCompleteUser) {
        participant.isComplete = true;
      } else {
        participant.isComplete = false;
      }
      return participant;
    });
  }

  get isBoardTypeStudy() {
    return this.board.category === '과제';
  }

  boardReset() {
    this.board = setBoardInit();
    this.files = [];
    this.participants = [];
  }

  async setContent() {
    this.board.content = await this.getContent();
  }

  getContent(): Promise<string> {
    return new Promise((resolve) => {
      resolve(this.editor.invoke('getHtml'));
    });
  }

  async uploadFile() {
    try {
      const type = 'board';
      const groupId = this.board._id_group;
      const fileIdx = this.board.filesDir ? this.board.filesDir.length : 0;
      const formData: FormData = new FormData();
      this.files.forEach((file, fileIdx) => {
        formData.append('fileList', file);
      });
      const { data } = await this.axios.patch(`/upload/${groupId}/${type}/${fileIdx}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (this.board.filesDir) {
        this.board.filesDir = [...this.board.filesDir, ...data];
      } else {
        this.board.filesDir = data;
      }
    } catch (e) {
      console.log('업로드중 에러발생');
    }
  }

  async updateBoard() {
    this.isLoad = true;
    try {
      await this.setContent();
      if (this.files.length) {
        await this.uploadFile();
      }
      const { data } = await this.axios.patch(`/board/${this.syncBoardId}`, this.board);
      if (data.result) {
        this.closeModal(true);
        this.isLoad = false;
      }
    } catch (e) {
      console.log('-----error----');
      console.log(e);
      console.log('-----error----');
    } finally {
      this.isLoad = false;
    }
  }

  get startDate(): string | null {
    if (this.board.startDate) {
      const day = dayjs(this.board.startDate);
      const YHD_FORMAT = day.format('YYYY-MM-DD');
      const HN_FORMAT = day.format('HH:MM');
      const TYPE = 'T';
      return `${YHD_FORMAT}${TYPE}${HN_FORMAT}`;
    } else {
      return null;
    }
  }

  get endDate(): string | null {
    if (this.board.endDate) {
      const day = dayjs(this.board.endDate);
      const YHD_FORMAT = day.format('YYYY-MM-DD');
      const HN_FORMAT = day.format('HH:MM');
      const TYPE = 'T';
      return `${YHD_FORMAT}${TYPE}${HN_FORMAT}`;
    } else {
      return null;
    }
  }

  get isReservation(): boolean {
    return this.board.reservation;
  }

  get content(): string {
    return this.board.content;
  }

  get isItFile(): boolean {
    return !!this.board.filesDir?.length;
  }

  @Emit('board-study-open')
  studyViewOpen(participantEmail: string) {
    // this.userEmail = studyId;
    // this.$bvModal.show('modal-study');
    // this.boardStudy.getIsSubmit(studyId, this.syncBoardId);
    return participantEmail;
  }

  @Emit()
  closeModal(isUpdate: boolean = false) {
    this.boardReset();
    return isUpdate;
  }

  @Emit()
  openUpdateModal() {}

  @Emit()
  async deleteBoard() {
    const { data } = await this.axios.delete(`board/${this.syncBoardId}`);
  }
}
