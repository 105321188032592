





































import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class TopShortcutRight extends Vue {
  @PropSync('search') syncSearch: string;
  @PropSync('sort') syncSort: number;
  @PropSync('page') syncPage: number;
  routerName = this.$route.path;
  title: string = '';

  mounted() {
    //#TODO 나중에 경로별 다 매칭 할 것
    if (this.routerName) {
      const isBoard = this.routerName.includes('board');
      if (isBoard) this.title = '게시판 관리';
    }
  }

  @Emit()
  changeHandle() {}

  sortChange() {
    this.syncSort = this.syncSort * -1;
    this.syncPage = 1;
    this.changeHandle();
  }
}
