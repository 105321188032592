import { IOptionValue } from '../utils/interface';

export namespace PARTICIPANT {
  export const enum INVITATION_STATUS {
    ALL = 'all',
    WAIT = 'wait',
    COMPLETED = 'completed',
    REFUSAL = 'refusal',
  }

  export const INVITATION_OPTIONS: Array<IOptionValue> = [
    { value: INVITATION_STATUS.ALL, text: 'ALL' },
    { value: INVITATION_STATUS.WAIT, text: '승인대기' },
    { value: INVITATION_STATUS.COMPLETED, text: '승인완료' },
    { value: INVITATION_STATUS.REFUSAL, text: '거절' },
  ];

  export const enum GENDER {
    MAN = 'man',
    WOMAN = 'woman',
  }

  export const GENDER_OPTIONS: Array<IOptionValue> = [
    { value: GENDER.MAN, text: '남자' },
    { value: GENDER.WOMAN, text: '여자' },
  ];

  export function getInitParticipant(): IParticipant {
    return {
      name: '',
      email: '',
      gender: PARTICIPANT.GENDER.MAN,
      birthDate: '',
      mobile: '',
      addFields: {},
      memos: [],
      invitationFavorite: false,
      participantFavorite: false,
      invitationGroups: [],
      invitationCategory: '',
      participantCategory: '',
    };
  }
}

export namespace SURVEY {
  export const enum QUESTION_TYPE {
    RADIO = 'radio',
    RADIO_SET = 'radioSet',
    CHECK = 'check',
    TEXT = 'text',
  }

  export const QUESTION_TYPE_OPTIONS: Array<IOptionValue> = [
    { value: QUESTION_TYPE.RADIO, text: '단일' },
    { value: QUESTION_TYPE.RADIO_SET, text: '단일(표)' },
    { value: QUESTION_TYPE.CHECK, text: '복수' },
    { value: QUESTION_TYPE.TEXT, text: '오픈' },
  ];
}

export interface IMemo {
  memo: string;
  regDate?: string;
}

export interface IParticipant {
  _id?: string; //고유값 ObjectId
  registerUserId?: string; //등록한 고유값 ObjectId
  name: string; // 참여자 이름
  email: string; // 참여자 이메일
  gender: PARTICIPANT.GENDER; //참여자 성별
  birthDate: string; //참여자 생년월일
  mobile: string; //참여자 전화번호
  memos: IMemo[]; //참여자 메모
  addFields?: object | null; //참여자 추가항목
  invitationFavorite: boolean; // 초대하기 즐겨찾기 여부
  participantFavorite: boolean; // 참여자관리 즐겨찾기 여부
  invitationCategory: string; // 초대하기 카테고리
  participantCategory: string; // 참여자관리 카테고리
  invitationGroups: Array<{ groupObjectId: string; status: PARTICIPANT.INVITATION_STATUS }>; //그룹 초대 상태
  insertDate?: Date; //등록날짜
  updateDate?: Date; // 수정날짜
  status?: string;
  checked?: boolean;
  statusText?: string;
  isComplete?: boolean;
}
