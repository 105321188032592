




















import { Component, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class TopShortcutMiddleTime extends Vue {
  routerName = this.$route.path;
  title: string = '';
  startDate: string = '';
  endDate: string = '';

  mounted() {
    //#TODO 나중에 경로별 다 매칭 할 것
    if (this.routerName) {
      const isBoard = this.routerName.includes('board');
      if (isBoard) this.title = '게시판 관리';
    }
  }
  sort: number = 0;
  search: string = '';
  sortChange() {}
  projectList() {}
}
