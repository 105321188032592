
























































import { Component, Mixins } from 'vue-property-decorator';
import Mixin from './mixin';
import { PROJECT } from '@/interface/project';

@Component
export default class RegisterProjectStep4 extends Mixins(Mixin) {
  constructor() {
    super();
  }

  get interviewType(): string {
    const { interviewType } = this.project;
    const find = PROJECT.INTERVIEW_TYPE_OPTIONS.find(
      (option) => option.value === interviewType
    );
    return find == undefined ? '-' : find.text;
  }
}
